






























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import Connectors from "./partials/charging-stations/Connectors.vue";
import appService from "@/services/appService";
import {connectorTypes} from "@/constants/connectorTypes";
import centrifugeClientFactory from "@/services/centrifugeClientFactory";
import merge from "@common/modules/vuetifyx/utils/merge";
import configProvider from "@common/services/configProvider";
import copy from "copy-to-clipboard";
import BlurredTenant from "@/components/BlurredTenant.vue";


export default Vue.extend({
    components: { Connectors, BlurredTenant },
    data() {
        const connectorTypeItems = Object.keys(connectorTypes).map(value => {
            return {text: connectorTypes[value], value};
        });
        const _this = this;
        return {
            showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
            self: this,
            tableContainer: new DataContainer(),
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "name",
                    "sort-desc": false,
                    "show-expand": true,
                },
                content: {
                    name: "Charging Stations",
                    urlEnabled: true,
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Organization",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call(
                                                    "tenants",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters: [
                                                                {
                                                                    key: "_id",
                                                                    operator: "equal_to",
                                                                    value,
                                                                },
                                                            ],
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            chargingLocationId: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Site",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const tenant = userManager.getUserInfo().tenant;
                                                const filters = [
                                                                {
                                                                    key: "_id",
                                                                    operator: "equal_to",
                                                                    value,
                                                                },
                                                            ];
                                                if (tenant) {
                                                    filters.push({
                                                        key: "tenantId",
                                                        operator: "equal_to",
                                                        value: tenant._id,
                                                    });
                                                }
                                                const { items } = await coreApiClient.call(
                                                    "chargingLocations",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters,
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            retailerId: userManager.checkRole([
                                "systemAdmin",
                                "admin",
                                "cpoAdmin", "cpoMonitor",
                            ]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Retailer",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call(
                                                    "retailers",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters: [
                                                                {
                                                                    key: "_id",
                                                                    operator: "equal_to",
                                                                    value,
                                                                },
                                                            ],
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            chargerVendorId: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Charger Vendor",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call(
                                                    "chargerVendors",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters: [
                                                                {
                                                                    key: "_id",
                                                                    operator: "equal_to",
                                                                    value,
                                                                },
                                                            ],
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            serialNumber: {
                                attrs: {
                                    label: "Serial Number",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                            uniqueId: {
                                attrs: {
                                    label: "Charge Point ID",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                            "connectorSettingItems.qrCode": {
                                attrs: {
                                    label: "QR Code",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                            "connectorSettingItems.type": {
                                type: "select",
                                attrs: {
                                    label: "Type",
                                    items: connectorTypeItems,
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                        },
                    },
                    displayFields: {
                        chargerPointName: {
                            text: "Charge Point Name",
                            sortable: true,
                            options: {
                                attrs: {
                                    class: "font-weight-bold",
                                },
                            },
                        },
                        uniqueId: {
                            text: "Charge Point ID",
                            sortable: true,
                            options: {
                                attrs: {
                                    class: "font-weight-bold",
                                },
                            },
                        },
                        connectors: {
                            text: "Connectors",
                        },
                        chargingLocation: {
                            text: "Site",
                            options: {
                                subProp: "chargingLocation.name",
                                label: true,
                                sortBy: "chargingLocationId",
                            },
                        },
                        chargerVendorBrand: {
                            text: "Model",
                            options: {
                                subProp: "chargerVendor.brand",
                            },
                        },
                        retailer: {
                            text: "Retailer",
                            options: {
                                subProp: "retailer.name",
                                label: true,
                                sortBy: "retailerId",
                            },
                        },
                        isPrivate: {
                            text: "Mode",
                            options: {
                                boolean: true,
                                label: (value: boolean) => {
                                    return value ? "Private" : "Public";
                                },
                                attrs(value) {
                                    return {
                                        color: value ? "red" : "green",
                                    };
                                },
                            },
                        },
                        chargerVendor: {
                            text: "Vendor",
                            options: {
                                subProp: "chargerVendor.name",
                                label: true,
                                sortBy: "chargerVendorId",
                            },
                        },
                        power: {
                            text: "Power (KW)",
                            sortable: true,
                        },
                        chargingLocationAddress: {
                            text: "Address",
                            options: {
                                subProp: "chargingLocation.address",
                                tooltip: {
                                    content: {
                                        text: "chargingLocation.address",
                                    },
                                },
                                attrs: {
                                    class: "address-station two-line-text",
                                    // style: "width: 150px !important;  " +
                                    //     "display: -webkit-box;\n" +
                                    //     "  -webkit-line-clamp: 2;\n" +
                                    //     "  -webkit-box-orient: vertical;\n" +
                                    //     "  overflow: hidden;\n" +
                                    //     "  text-overflow: ellipsis;\n" +
                                    //     "  word-wrap: break-word;"
                                },
                            },
                        },
                        createdTime: {
                            text: "Created Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        updatedTime: {
                            text: "Updated Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
                            text: "Organization",
                            options: {
                                subProp: "tenant.name",
                                label: true,
                                sortBy: "tenantId",
                            },
                        },
                        action: {
                            text: "Actions",
                        },
                    },
                    multiSelectActionButtons: {
                        export: userManager.checkRole([
                            "systemAdmin",
                            "admin",
                            "cpoAdmin", "cpoMonitor",
                            "retailer",
                        ]) && {
                            content: {
                                text: "Export selected",
                                icon: "mdi-microsoft-excel",
                            },
                            on: {
                                xClick({ self }) {
                                    const dataTable = self.context();
                                    coreApiClient.download("chargingStations", `@/export`, {
                                        payload: JSON.stringify({
                                            filters: [
                                                {
                                                    key: "_id",
                                                    operator: "in",
                                                    value: dataTable.model.value.map((item) => item._id),
                                                },
                                            ],
                                            limit: -1,
                                            extraParams: {
                                                currentTimeZoneOffset:
                                                    new Date().getTimezoneOffset() * -1 * 60 * 1000,
                                            },
                                        }),
                                    });
                                },
                            },
                        },
                    },
                    topActionButtons: {
                        export: userManager.checkRole([
                            "systemAdmin",
                            "admin",
                            "cpoAdmin", "cpoMonitor",
                            "retailer",
                        ]) && {
                            content: {
                                text: "Export all",
                                icon: "mdi-microsoft-excel",
                            },
                            on: {
                                xClick({ self }) {
                                    const dataTable = self.context();
                                    coreApiClient.download(
                                        "chargingStations",
                                        `@/export`,
                                        dataTable.getFindAllOptions()
                                    );
                                },
                            },
                        },
                        insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                            target: {
                                dialog: {
                                    attrs: {
                                        width: "600px",
                                    },
                                },
                            },
                            ext: {
                                condition() {
                                    return appService.activeTenantValid();
                                },
                            },
                        },
                    },
                    template: {
                        itemActionButtons: {
                            edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                                target: {
                                    dialog: {
                                        attrs: {
                                            width: "600px",
                                        },
                                        ext: {
                                            subTitleMaker: "uniqueId",
                                        },
                                    },
                                },
                            },
                            delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
                            more: {
                                content: {
                                    icon: "mdi-dots-vertical",
                                },
                                target: {
                                    tooltip: {
                                        content: {
                                            text: "More Actions",
                                        },
                                    },
                                    menu: {
                                        content: {
                                            items: {
                                                viewChargingSessions: userManager.checkRole([
                                                    "systemAdmin",
                                                    "admin",
                                                    "cpoAdmin", "cpoMonitor",
                                                    "retailer",
                                                    "user",
                                                ]) && {
                                                    content: {
                                                        text: "View Charging Sessions",
                                                    },
                                                    on: {
                                                        xClick({ self }) {
                                                            const menu = self.context();
                                                            const { dataTable, item } = menu.context();
                                                            const { $router } = dataTable.context();
                                                            $router.push({
                                                                path: "/app/charging_sessions",
                                                                query: {
                                                                    chargingStationId: item._id,
                                                                },
                                                            });
                                                        },
                                                    },
                                                },
                                                viewOcppParameters: userManager.checkRole([
                                                    "systemAdmin",
                                                    "admin",
                                                    "cpoAdmin", 
                                                    "cpoMonitor",
                                                    "retailer"
                                                ]) && {
                                                    content: {
                                                        text: "View OCPP Parameters",
                                                    },
                                                    target: {
                                                        dialog: {
                                                            attrs: {
                                                                width: "600px",
                                                            },
                                                            content: {
                                                                toolbar: {
                                                                    title: "OCPP Parameters",
                                                                    subTitle(dialog) {
                                                                        const menu = dialog.context();
                                                                        const btn = menu.context();
                                                                        const { item } = btn.context();
                                                                        return item.uniqueId;
                                                                    },
                                                                },
                                                                content: {
                                                                    type: "XForm",
                                                                    makeAttrs: (attrs, dialog) => {
                                                                        attrs.xContext = dialog;
                                                                        const { item } = dialog
                                                                            .context()
                                                                            .context()
                                                                            .context();
                                                                        
                                                                        attrs.xData = new DataContainer({});
                                                                        // attrs.xDisabled = new DataContainer(true);
                                                                        attrs.dataProvider = {
                                                                            getData:  (form) => {
                                                                                return coreApiClient.call(
                                                                                    "chargingStations",
                                                                                    "getOcppParameters",
                                                                                    {id: item.id}
                                                                                ).then((res) => {
                                                                                    const fields = {};
                                                                                    const xData = {};
                                                                                    res.map(item => {
                                                                                        fields[item.key] = {};
                                                                                        xData[item.key] = item.value;
                                                                                    })

                                                                                    form.options.content.sections = {
                                                                                        default: {
                                                                                            fields: fields,
                                                                                        }
                                                                                    };

                                                                                   return xData;
                                                                                });
                                                                            }
                                                                        }
                                                                       
                                                                        return attrs;
                                                                    },
                                                                    attrs: {
                                                                        xOptions: {
                                                                            content: {
                                                                                sections: {
                                                                                    default: {}
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                },
                                                                buttons: {
                                                                    export: {
                                                                        content: {
                                                                            text: "Export",
                                                                        },
                                                                        states: {},
                                                                        on: {
                                                                            async xClick({self}) {
                                                                                const { item } = self
                                                                                    .context()
                                                                                    .context()
                                                                                    .context()
                                                                                    .context();
                                                                                const chargeId =  item.id;

                                                                                coreApiClient.download(
                                                                                    "chargingStations",
                                                                                    `${chargeId}/ocpp/parameters/export`
                                                                                );
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            }
                                                        },
                                                    },
                                                },
                                                firmwareUpdate: userManager.checkRole([
                                                    "systemAdmin",
                                                    "admin",
                                                    "cpoAdmin", 
                                                    "retailer",
                                                ]) && {
                                                    content: {
                                                        text: "Firmware Update",
                                                    },
                                                    target: {
                                                        dialog: {
                                                            attrs: {
                                                                width: "600px",
                                                            },
                                                            content: {
                                                                toolbar: {
                                                                    title: "Firmware Update",
                                                                    subTitle(dialog) {
                                                                        const menu = dialog.context();
                                                                        const btn = menu.context();
                                                                        const { item } = btn.context();
                                                                        return item.uniqueId;
                                                                    },
                                                                },
                                                                content: {
                                                                    type: "XForm",
                                                                    makeAttrs: (attrs, dialog) => {
                                                                        attrs.xContext = dialog;                                                                       
                                                                        return attrs;
                                                                    },
                                                                    attrs: {
                                                                        xOptions: {
                                                                            content: {
                                                                                sections: {
                                                                                    default: {
                                                                                        fields: {
                                                                                            location: {
                                                                                                attrs: {
                                                                                                    label: "URL",
                                                                                                    required: true,
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                },
                                                                buttons: {
                                                                    update: {
                                                                        content: {
                                                                            text: "Update",
                                                                        },
                                                                        states: {},
                                                                        on: {
                                                                            async xClick({self}) {
                                                                                const dialog = self.context();
                                                                                const menu = dialog.context();
                                                                                const btn = menu.context();
                                                                                const { item, dataTable } =
                                                                                    btn.context();
                                                                                const form =
                                                                                    dialog.contentContainer.value;
                                                                                const data = form.getData();
                                                                                self.loading.value = true;
                                                                                const result = await dataTable.execute(
                                                                                    async () => {
                                                                                        return await coreApiClient.call(
                                                                                            "chargingStations",
                                                                                            "firmwareUpdate",
                                                                                            {
                                                                                                id: item._id,
                                                                                            },
                                                                                            data
                                                                                        );
                                                                                    },
                                                                                    undefined,
                                                                                    {
                                                                                        disableLoading: true,
                                                                                        willRefresh: true,
                                                                                        successMessage: `The request to update the firmware of the charging station '${item.uniqueId}' has been successfully sent`
                                                                                    }
                                                                                );
                                                                                self.loading.value = false;
                                                                                if (result) {
                                                                                    dialog.hide();
                                                                                }
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            }
                                                        },
                                                    },
                                                },
                                                resetCharger: userManager.checkRole([
                                                    "systemAdmin",
                                                    "admin",
                                                    "cpoAdmin",
                                                    "retailer",
                                                ]) && {
                                                    content: {
                                                        text: "Reset Charger",
                                                    },
                                                    target: {
                                                        confirm: {
                                                            content: {
                                                                content: {
                                                                    html: "Are you sure to reset this charger?",
                                                                },
                                                            },
                                                            on: {
                                                                async xYes({ self }) {
                                                                    const yesBtn = self.yesBtnContainer.value;
                                                                    const menu = self.context();
                                                                    const { dataTable, item } = menu
                                                                        .context()
                                                                        .context();
                                                                    yesBtn.loading.value = true;
                                                                    const result = await dataTable.execute(
                                                                        async () => {
                                                                            return await coreApiClient.call(
                                                                                "chargingStations",
                                                                                "reset",
                                                                                {
                                                                                    id: item._id,
                                                                                }
                                                                            );
                                                                        },
                                                                        undefined,
                                                                        {
                                                                            disableLoading: true,
                                                                            willRefresh: true,
                                                                        }
                                                                    );
                                                                    yesBtn.loading.value = false;
                                                                    if (result) {
                                                                        self.hide();
                                                                    }
                                                                },
                                                            },
                                                        },
                                                    },
                                                },
                                                editAuthorizedCards: userManager.checkRole([
                                                    "systemAdmin",
                                                    "admin",
                                                    "cpoAdmin",
                                                    "retailer",
                                                ]) && {
                                                    content: {
                                                        text: "Edit Authorize Cards",
                                                    },
                                                    target: {
                                                        dialog: {
                                                            handlers: {
                                                                initialize() {
                                                                    this.options.content.buttons.save.states.enabled =
                                                                        new DataContainer(false);
                                                                },
                                                            },
                                                            content: {
                                                                toolbar: {
                                                                    title: "Edit Authorize Cards",
                                                                    subTitle(dialog) {
                                                                        const menu = dialog.context();
                                                                        const btn = menu.context();
                                                                        const { item } = btn.context();
                                                                        return item.uniqueId;
                                                                    },
                                                                },
                                                                content: {
                                                                    type: "XForm",
                                                                    makeAttrs(attrs, dialog) {
                                                                        const { item } = dialog
                                                                            .context()
                                                                            .context()
                                                                            .context();
                                                                        attrs.xModel =
                                                                            dialog.options.content.buttons.save.states.enabled;
                                                                        attrs.xContext = dialog;
                                                                        attrs.xData = new DataContainer({
                                                                            authorizedCardItems:
                                                                                (item.authorizedCards &&
                                                                                    item.authorizedCards.map((value) => ({
                                                                                        value,
                                                                                    }))) ||
                                                                                [],
                                                                        });
                                                                        attrs.xOptions = {
                                                                            content: {
                                                                                sections: {
                                                                                    default: {
                                                                                        fields: {
                                                                                            authorizedCardItems: {
                                                                                                type: "XArrayInput",
                                                                                                attrs: {
                                                                                                    label: "Authorize Cards",
                                                                                                    required: true,
                                                                                                    xOptions: {
                                                                                                        content: {
                                                                                                            itemProperties: {
                                                                                                                value: {
                                                                                                                    attrs: {
                                                                                                                        required: true,
                                                                                                                    },
                                                                                                                },
                                                                                                            },
                                                                                                            template: {
                                                                                                                formOptions: {
                                                                                                                    content: {
                                                                                                                        colLength: 12,
                                                                                                                    },
                                                                                                                },
                                                                                                            },
                                                                                                        },
                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            },
                                                                        };
                                                                        return attrs;
                                                                    },
                                                                    attrs: {},
                                                                },
                                                                buttons: {
                                                                    save: {
                                                                        content: {
                                                                            text: "Save",
                                                                        },
                                                                        states: {},
                                                                        on: {
                                                                            async xClick({ self }) {
                                                                                const dialog = self.context();
                                                                                const menu = dialog.context();
                                                                                const btn = menu.context();
                                                                                const { item, dataTable } =
                                                                                    btn.context();
                                                                                const form =
                                                                                    dialog.contentContainer.value;
                                                                                const data = form.getData();
                                                                                self.loading.value = true;
                                                                                const result = await dataTable.execute(
                                                                                    async () => {
                                                                                        return await coreApiClient.call(
                                                                                            "chargingStations",
                                                                                            "update",
                                                                                            {
                                                                                                id: item._id,
                                                                                            },
                                                                                            {
                                                                                                data: {
                                                                                                    authorizedCards:
                                                                                                        data.authorizedCardItems.map(
                                                                                                            (item) => item.value
                                                                                                        ),
                                                                                                },
                                                                                            }
                                                                                        );
                                                                                    },
                                                                                    undefined,
                                                                                    {
                                                                                        disableLoading: true,
                                                                                        willRefresh: true,
                                                                                    }
                                                                                );
                                                                                self.loading.value = false;
                                                                                if (result) {
                                                                                    dialog.hide();
                                                                                }
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                    ext: {
                                                        condition(self) {
                                                            const { item } = self.context().context();
                                                            return item?.isPrivate;
                                                        },
                                                    },
                                                },
                                                editAuthorizedVids: configProvider.get(
                                                    "AUTOCHARGE_ENABLED"
                                                ) &&
                                                    userManager.checkRole([
                                                        "systemAdmin",
                                                        "admin",
                                                        "cpoAdmin",
                                                        "retailer",
                                                    ]) && {
                                                    content: {
                                                        text: "Edit Authorize Vehicles",
                                                    },
                                                    target: {
                                                        dialog: {
                                                            handlers: {
                                                                initialize() {
                                                                    this.options.content.buttons.save.states.enabled =
                                                                        new DataContainer(false);
                                                                },
                                                            },
                                                            content: {
                                                                toolbar: {
                                                                    title: "Edit Authorize Vehicles",
                                                                    subTitle(dialog) {
                                                                        const menu = dialog.context();
                                                                        const btn = menu.context();
                                                                        const { item } = btn.context();
                                                                        return item.uniqueId;
                                                                    },
                                                                },
                                                                content: {
                                                                    type: "XForm",
                                                                    makeAttrs(attrs, dialog) {
                                                                        const { item } = dialog
                                                                            .context()
                                                                            .context()
                                                                            .context();
                                                                        attrs.xModel =
                                                                            dialog.options.content.buttons.save.states.enabled;
                                                                        attrs.xContext = dialog;
                                                                        attrs.xData = new DataContainer({
                                                                            authorizedVidItems:
                                                                                (item.authorizedVids &&
                                                                                    item.authorizedVids.map(
                                                                                        (value) => ({ value })
                                                                                    )) ||
                                                                                [],
                                                                        });
                                                                        attrs.xOptions = {
                                                                            content: {
                                                                                sections: {
                                                                                    default: {
                                                                                        fields: {
                                                                                            authorizedVidItems: {
                                                                                                type: "XArrayInput",
                                                                                                attrs: {
                                                                                                    label: "Authorize Vehicles",
                                                                                                    required: true,
                                                                                                    xOptions: {
                                                                                                        content: {
                                                                                                            itemProperties: {
                                                                                                                value: {
                                                                                                                    attrs: {
                                                                                                                        required: true,
                                                                                                                    },
                                                                                                                },
                                                                                                            },
                                                                                                            template: {
                                                                                                                formOptions: {
                                                                                                                    content: {
                                                                                                                        colLength: 12,
                                                                                                                    },
                                                                                                                },
                                                                                                            },
                                                                                                        },
                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            },
                                                                        };
                                                                        return attrs;
                                                                    },
                                                                    attrs: {},
                                                                },
                                                                buttons: {
                                                                    save: {
                                                                        content: {
                                                                            text: "Save",
                                                                        },
                                                                        states: {},
                                                                        on: {
                                                                            async xClick({ self }) {
                                                                                const dialog = self.context();
                                                                                const menu = dialog.context();
                                                                                const btn = menu.context();
                                                                                const { item, dataTable } =
                                                                                    btn.context();
                                                                                const form =
                                                                                    dialog.contentContainer.value;
                                                                                const data = form.getData();
                                                                                self.loading.value = true;
                                                                                const result =
                                                                                    await dataTable.execute(
                                                                                        async () => {
                                                                                            return await coreApiClient.call(
                                                                                                "chargingStations",
                                                                                                "update",
                                                                                                {
                                                                                                    id: item._id,
                                                                                                },
                                                                                                {
                                                                                                    data: {
                                                                                                        authorizedVids:
                                                                                                            data.authorizedVidItems.map(
                                                                                                                (item) => item.value
                                                                                                            ),
                                                                                                    },
                                                                                                }
                                                                                            );
                                                                                        },
                                                                                        undefined,
                                                                                        {
                                                                                            disableLoading: true,
                                                                                            willRefresh: true,
                                                                                        }
                                                                                    );
                                                                                self.loading.value = false;
                                                                                if (result) {
                                                                                    dialog.hide();
                                                                                }
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                    ext: {
                                                        condition(self) {
                                                            const { item } = self.context().context();
                                                            return item?.isPrivate;
                                                        },
                                                    },
                                                },
                                                editAppPassword: userManager.checkRole([
                                                    "systemAdmin",
                                                    "admin",
                                                    "cpoAdmin",
                                                ]) && {
                                                    content: {
                                                        text: "Edit App Charging",
                                                    },
                                                    target: {
                                                        dialog: {
                                                            handlers: {
                                                                initialize() {
                                                                    this.options.content.buttons.save.states.enabled =
                                                                        new DataContainer(false);
                                                                },
                                                            },
                                                            content: {
                                                                autofocus: false,
                                                                toolbar: {
                                                                    title: "Edit App Charging",
                                                                    subTitle(dialog) {
                                                                        const menu = dialog.context();
                                                                        const btn = menu.context();
                                                                        const { item } = btn.context();
                                                                        return item.uniqueId;
                                                                    },
                                                                },
                                                                content: {
                                                                    type: "XForm",
                                                                    makeAttrs(attrs, dialog) {
                                                                        const { item } = dialog
                                                                            .context()
                                                                            .context()
                                                                            .context();
                                                                        attrs.xModel =
                                                                            dialog.options.content.buttons.save.states.enabled;
                                                                        attrs.xContext = dialog;
                                                                        attrs.xData = new DataContainer({
                                                                            appChargingAllowed:
                                                                                item.appChargingAllowed,
                                                                            appChargingPassword:
                                                                                item.appChargingPassword,
                                                                        });
                                                                        attrs.xOptions = {
                                                                            content: {
                                                                                sections: {
                                                                                    default: {
                                                                                        fields: {
                                                                                            appChargingAllowed: {
                                                                                                type: "boolean",
                                                                                                attrs: {
                                                                                                    label: "Allow App Charging",
                                                                                                },
                                                                                            },
                                                                                            appChargingPassword: {
                                                                                                type: "XPasswordInput",
                                                                                                attrs: {
                                                                                                    label: "Password",
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            },
                                                                        };
                                                                        return attrs;
                                                                    },
                                                                    attrs: {},
                                                                },
                                                                buttons: {
                                                                    save: {
                                                                        content: {
                                                                            text: "Save",
                                                                        },
                                                                        states: {},
                                                                        on: {
                                                                            async xClick({ self }) {
                                                                                const dialog = self.context();
                                                                                const menu = dialog.context();
                                                                                const btn = menu.context();
                                                                                const { item, dataTable } =
                                                                                    btn.context();
                                                                                const form =
                                                                                    dialog.contentContainer.value;
                                                                                const data = form.getData();
                                                                                self.loading.value = true;
                                                                                const result = await dataTable.execute(
                                                                                    async () => {
                                                                                        return await coreApiClient.call(
                                                                                            "chargingStations",
                                                                                            "update",
                                                                                            {
                                                                                                id: item._id,
                                                                                            },
                                                                                            {
                                                                                                data,
                                                                                            }
                                                                                        );
                                                                                    },
                                                                                    undefined,
                                                                                    {
                                                                                        disableLoading: true,
                                                                                        willRefresh: true,
                                                                                    }
                                                                                );
                                                                                self.loading.value = false;
                                                                                if (result) {
                                                                                    dialog.hide();
                                                                                }
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                    ext: {
                                                        condition(self) {
                                                            const { item } = self.context().context();
                                                            return item?.isPrivate;
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            copyOcppUrl: userManager.checkRole([
                                "systemAdmin",
                                "admin",
                                "cpoAdmin", "cpoMonitor",
                            ]) && {
                                content: {
                                    icon: "mdi-clipboard-multiple",
                                },
                                on: {
                                    xClick({ self }) {
                                        const { item, dataTable } = self.context();
                                        const url = `${configProvider.get("OCPP_BASE_URL")}/${item.tenantId
                                            }/${item.retailerId}`;
                                        copy(url);
                                        dataTable.info.value = `OCPP URL has been copied to clipboard`;
                                    },
                                },
                                target: {
                                    tooltip: {
                                        content: {
                                            text: "Copy OCPP URL",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    findAllOptionsFilter(options) {
                        const activeTenant = appService.getActiveTenant();
                        if (activeTenant) {
                            options.filters.push({
                                key: "tenantId",
                                operator: "equal_to",
                                value: activeTenant._id,
                            });
                        }

                        if (userManager.checkRole(["retailer"], true)) {
                            options.filters.push({
                                key: "retailerUserId",
                                operator: "equal_to",
                                value: userManager.getUserInfo()._id,
                            });
                        }

                        options.extraParams.currentTimeZoneOffset =
                            new Date().getTimezoneOffset() * -1 * 60 * 1000;
                        return options;
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const payload = JSON.parse(options.payload || "{}");
                            const filters = payload.filters || [];
                            if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], true)) {
                                const tenantId = userManager.getUserInfo().tenant._id;
                                filters.push({
                                    key: "tenantId",
                                    operator: "equal_to",
                                    value: tenantId,
                                });
                                options.payload = JSON.stringify({
                                    ...payload,
                                    filters,
                                });
                            }
                            const { items, count } = await coreApiClient.call(
                                "chargingStations",
                                "findAll",
                                options
                            );
                            return [items, count];
                        },
                        async insert(item) {
                            if (item.files?.length > 0) {
                                const imagesBase64 = await Promise.all(item.files.map(f => _this.createImage(f))).then(images => images.filter(i => !!i));
                                if (imagesBase64?.length > 0) {
                                    item.images = imagesBase64;
                                }
                            }
                            delete item.files;
                            return await coreApiClient.call(
                                "chargingStations",
                                "create",
                                undefined,
                                item
                            );
                        },
                        async update(item) {
                            delete item.images;
                            if (item.files?.length > 0) {
                                const imagesBase64 = await Promise.all(item.files.map(f => _this.createImage(f))).then(images => images.filter(i => !!i));
                                if (imagesBase64?.length > 0) {
                                    item.images = imagesBase64;
                                }
                            }
                            delete item.files;
                            return await coreApiClient.call(
                                "chargingStations",
                                "update",
                                {
                                    id: item._id,
                                },
                                {
                                    data: item,
                                }
                            );
                        },
                        async delete(item) {
                            return await coreApiClient.call("chargingStations", "delete", {
                                id: item._id,
                            });
                        },
                    },
                    default: {
                        defaultInputs: {
                            chargingLocationId: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Site",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const tenant = userManager.getUserInfo().tenant;
                                                const filters = [
                                                    {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                    },
                                                ];
                                                if (tenant) {
                                                    filters.push({
                                                        key: "tenantId",
                                                        operator: "equal_to",
                                                        value: tenant._id,
                                                    });
                                                }
                                                const { items } = await coreApiClient.call(
                                                    "chargingLocations",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters,
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                            },
                            retailerId: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Retailer",
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const filters = [
                                                    {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                    },
                                                ];
                                                const { items } = await coreApiClient.call(
                                                    "retailers",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters,
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                            },
                            chargerVendorId: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Charger Vendor",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const filters = [
                                                    {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                    },
                                                ];
                                                const { items } = await coreApiClient.call(
                                                    "chargerVendors",
                                                    "findAll",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters,
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                            },
                            serialNumber: {
                                attrs: {
                                    label: "Serial Number",
                                    required: true,
                                },
                            },
                            uniqueId: {
                                attrs: {
                                    label: "Charge Point ID",
                                    required: true,
                                },
                            },
                            isPrivate: {
                                type: "boolean",
                                attrs: {
                                    label: "Private",
                                },
                            },
                            chargerPointName: {
                                attrs: {
                                    label: "Charge Point Name",
                                    required: true,
                                    default: "Charge Name"
                                },
                            },
                            power: {
                                type: "number",
                                attrs: {
                                    label: "Power (KW)",
                                },
                            },
                            files: {
                                type: "file",
                                attrs: {
                                    label: "Images",
                                    accept: "image/*",
                                    multiple: true
                                },
                            },
                            connectorSettingItems: {
                                type: "XArrayInput",
                                attrs: {
                                    label: "Connector Settings",
                                    required: true,
                                    xOptions: {
                                        content: {
                                            itemLabel: (_, index) => {
                                                return `Connector ${+index + 1}`;
                                            },
                                            itemProperties: {
                                                type: {
                                                    type: 'select',
                                                    attrs: {
                                                        label: "Type",
                                                        items: connectorTypeItems,
                                                        required: true,
                                                    },
                                                },
                                                qrCode: {
                                                    attrs: {
                                                        label: "QR Code",
                                                        required: true,
                                                    },
                                                },
                                            },
                                            template: {
                                                formOptions: {
                                                    content: {
                                                        colLength: 12,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                            content: {
                                fieldNames: [
                                    "chargerPointName",
                                    "uniqueId",
                                    "serialNumber",
                                    "chargingLocationId",
                                    "retailerId",
                                    "chargerVendorId",
                                    "power",
                                    "isPrivate",
                                    "files",
                                    "connectorSettingItems",
                                ],
                            },
                        },
                        editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                            content: {
                                fieldNames: [
                                    "chargerPointName",
                                    "serialNumber",
                                    "chargingLocationId",
                                    "retailerId",
                                    "chargerVendorId",
                                    "power",
                                    "isPrivate",
                                    "files",
                                    "connectorSettingItems",
                                ],
                            },
                        },
                        delete: {
                            confirmDisplayField: "uniqueId",
                        },
                    },
                },
            },
        };
    },
    methods: {
        updateChargingStation(item) {
            const dataTable = this.tableContainer.value;
            const index = dataTable.items.value.findIndex(
                (it) => it.uniqueId === item.uniqueId
            );
            if (index >= 0) {
                const previousItem = dataTable.items.value[index];
                this.$set(
                    dataTable.items.value,
                    index,
                    merge.clone(previousItem, item)
                );
            }
        },
        createImage(file) {
            return new Promise((resolve, reject) => {
                if (file && typeof file !== "string") {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(null);
                }
            });
        },
    },
    created() {
        const activeTenant = appService.getActiveTenant();

        const socketId = userManager.getUserInfo()._id;

        let channelName = "";

        if (activeTenant) {
            channelName = `private-chargingStations-tenantId=${activeTenant._id}`;
        } else if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                }`;
        } else if (userManager.checkRole(["retailer"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                }-retailerAdminUserId=${userManager.getUserInfo()._id}`;
        } else if (userManager.checkRole(["user"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                }-connectorItemsActiveUserId=${userManager.getUserInfo()._id}`;
        } else {
            channelName = `private-chargingStations`;
        }

        const centrifuge = centrifugeClientFactory(null, async () => {
            const {
                auth
            } = await coreApiClient.call("chargingStations", "subscribe", {
                channelName,
                socketId,
                id: "",
                connectorId: 0,
            });
            return auth;
        });
        this.centrifuge = centrifuge
        const self = this;


        if (channelName) {
            const sub = this.centrifuge.newSubscription(channelName)

            sub.on("publication", (ctx) => {
                const data = (ctx.data);
                self.updateChargingStation(data);
            });
            sub.subscribe();
            centrifuge.connect();
        }
    },
    destroyed() {
        if (this.centrifuge) {
            this.centrifuge.disconnect();
        }
    },
});
